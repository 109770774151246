import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[5]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[6]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[150px] pb-[100px] md:pb-[180px] md:pt-[250px]  ">
          <div className="w-full flex flex-col justify-center items-center">
            <h2 className="text-white bg-red-600 p-4 text-2xl rounded-lg">Hablamos Español !!</h2>
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[2].slogan}
            </h1>
            <p className="text-white text-center px-0 md:px-16">{rpdata?.dbAbout?.[2].text}</p>
            <div className="flex justify-center md:block">
              <ButtonContent btnName={"Schedule Online"} btnLink={'contact'} />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
