import React, { useContext, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { GlobalDataContext } from "../../context/context";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import SocialMedia from "../global/SocialMedia";

const dbBlog = [
    {
        title: '5 Air Conditioner Problems and How to Fix Them',
        description: [
            {
                subtitle: '',
                parrafo: 'Air conditioners need a little TLC to deliver optimal performance. Here are five questions to ask that will help you determine what’s ailing your AC.',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63e65ebd7c1e0108970eb953%2Fgallery%2FCocina%20Solutions%20LLC-2023-04-05T21%3A00%3A50.109Z-0.jpeg?alt=media&token=a10729e0-e403-4e88-8319-048472ca75cf',
                steps: [
                    {
                        title: 'Is the filter dirty?',
                        info: 'A clogged air filter restricts airflow, reducing efficiency and ability to cool the air efficiently. For central AC systems, check the filter manufacturer’s for when to change in a new one. HVAC (heating, ventilation, and air conditioning) filters last three to 12 months.Keep in mind that if you have pets, you’ll need to replace your filters more often. When a central air unit isn’t working well, eight times out of 10 it’s because of a dirty filter.',
                    },
                    {
                        title: 'Is the thermostat on the right setting?',
                        info: 'A smart or programmable thermostat can control the temperature in your home more easily and if the temperature seems off, make sure the thermostat isn’t exposed to direct sunlight, which may cause it to register the wrong temperature.',
                    },
                    {
                        title: 'Are the registers dirty or blocked?',
                        info: 'If you have a forced-air heating and cooling system, regularly vacuum the registers to remove any dust buildup. Make sure that furniture and other objects aren’t blocking the airflow through your registers.',
                    },
                    {
                        title: 'Are plants crowding the compressor?',
                        info: 'The outdoor compressor for central air needs adequate airflow to work correctly, so make sure there’s at least 2 to 3 feet of space between it and any plants or structures. There should also be 5 feet of clearance between the top of the unit and any trees above.',
                    },
                    {
                        title: 'Did you remember the annual checkup?',
                        info: 'An inspection by an HVAC tech can catch any serious problems before they get worse. The technician will check all the moving parts as well as the refrigerant, and recharge the system if necessary.',
                    },
                ],
            },
            {
                subtitle: 'Need a New AC?',
                parrafo: 'If your air conditioner is beyond repair, you can find one that did well with your budget and needs. A New Air Conditioning is a long-term investment. Trust our dedicated experts at Cocina Solutions to install the best system to your needs. Ready to get started? Give us a call today at 325-232-8740, or request a free quote.',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63e65ebd7c1e0108970eb953%2Fgallery%2FCocina%20Solutions%20LLC-2023-02-18T16%3A30%3A12.023Z-1.jpeg?alt=media&token=51d2bf33-6524-42bd-8e48-da4822ec5a0d',
                steps: [],

            },

        ],

    },
    {
        title: 'Why Kitchen Exhaust Hoods are Important',
        description: [
            {
                subtitle: '',
                parrafo: 'Commercial-grade restaurant kitchens have the added danger of multiple sources of fire and heat, raising the risk of fire substantially. To manage the fire danger, it is vital to have a kitchen exhaust hood system professionally installed and cleaned at regular intervals. Cocina Solutions has proudly served Abilene and the surroundings restaurants with our impressive exhaust cleaning services for over 5 years. Installing and maintaining these systems is vital for reliable fire safety for years to come. Is your commercial kitchen ready for primetime?',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63e65ebd7c1e0108970eb953%2Fstock%2FCocina%20Solutions%20LLC-2023-04-12T22%3A28%3A11.553Z-0.jpg?alt=media&token=02f08583-4210-4b88-9dcf-92a9d139a952',
                steps: [],
            },
            {
                subtitle: '',
                parrafo: 'The primary purpose of a kitchen exhaust system is to remove cooking byproducts such as grease, oils, smoke, and heat. Typically placed over every burner, fryer, and oven, these systems work in conjunction with a restaurant fire suppression system to help ventilate smoke and heat, while the chemical fire agent extinguishes the flames. It is important to keep these kitchen exhaust systems clean to help maintain their peak functionality in everyday use, and emergency fire situations. For both professional chefs and amateurs, accidents can happen. You hope to never burn your food, but if a small fire does occur, you should always be adequately protected. Safety is the best investment! Trust our dedicated experts at Cocina Solutions to install the best system for your restaurant. Ready to get started? Give us a call today at 325-232-8740, or request a free quote.',
                imgDes: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F63e65ebd7c1e0108970eb953%2Fstock%2FCocina%20Solutions%20LLC-2023-04-12T22%3A28%3A11.553Z-1.jpg?alt=media&token=313a4fed-4c9d-456c-8294-3be825e25fb1',
                steps: [],
            },
        ],
        steps: [],
    },
]


function CardBlog() {
    const { rpdata } = useContext(GlobalDataContext);
    const [itemMenu, setItemMenu] = useState('5 Air Conditioner Problems and How to Fix Them')


    return (
        <div className="w-[90%] mx-auto pt-10 pb-0 md:py-10 flex flex-col md:flex-row">
            <div className="md:w-[30%] w-full h-auto px-5 md:px-10 py-12 border bg1 rounded-2xl">
                <h2 className="text-[40px] pb-7 text-white">Our Blogs</h2>
                <ul className="space-y-2">
                    {
                        dbBlog.map((item, index) => {
                            return (
                                <li key={index} className="flex gap-3 pb-5 items-center cursor-pointer text-white font-semibold hover:font-extrabold duration-300 ease-in-out" onClick={() => setItemMenu(item.title)}>
                                    <FiChevronRight />
                                    <span>{item.title}</span>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="pb-5">
                    <h4 className="text-[40px] pb-1 text-white">Contact</h4>
                    <ul className="px-1 md:px-4">
                        {
                            rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                return (
                                    <li key={index} className='flex items-center text-white pb-3'>
                                        <a
                                            href={`tel:+1${phone.phone}`}
                                            className='flex items-center'
                                        >
                                            <BiPhoneCall />
                                            <span className="pl-2">
                                                {phone.phone}
                                            </span>
                                        </a>
                                    </li>
                                )
                            })
                        }
                        {
                            rpdata?.dbPrincipal?.emails.map((email, index) => {
                                return (
                                    <li
                                        key={index}
                                        className='flex items-center text-white pb-3'
                                    >
                                        <a
                                            href={`mailto:${email.email}`}
                                            className='flex items-center'
                                        >
                                            <MdOutlineEmail />
                                            <span className="pl-2">
                                                {email.email}
                                            </span>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                {
                    rpdata?.dbSocialMedia?.redes?.length > 1 ?
                        <div className="pb-5 text-center md:text-start">
                            <h4 className="text-[40px] text-white" >Follow Us</h4>
                            <SocialMedia />
                        </div>
                        : null
                }
            </div>
            <div className="md:w-[70%] w-full h-auto md:px-10 px-5 py-8">
                {
                    dbBlog.map((item, index) => {
                        switch (itemMenu) {
                            case item.title:
                                return (
                                    <div key={index}>
                                        <h2 className="text-[40px] px-0 md:px-[10%] text-center">{item.title}</h2>
                                        <div>
                                            {
                                                item.description.map((desp, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <img
                                                                className="w-full h-[400px] object-cover rounded-2xl my-5"
                                                                src={
                                                                    desp.imgDes
                                                                } alt="not-found" />
                                                            <p className="pb-5">{desp.parrafo}</p>
                                                            <ul>
                                                                {
                                                                    desp.steps ?
                                                                        desp.steps.map((step, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    <div className="flex gap-2 items-center">
                                                                                        <p>
                                                                                            <span className="text-[20px] text-[#DE0A0C] font-bold">{index + 1}
                                                                                            </span> -
                                                                                        </p>
                                                                                        <p className="font-bold capitalize">{step.title}</p>
                                                                                    </div>
                                                                                    <p className="px-7">{step.info}</p>
                                                                                </li>
                                                                            )
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                            </ul>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )

                            default:
                                return null
                        }

                    })
                }
            </div>
        </div>
    );
}

export default CardBlog;
