import React, { useContext } from 'react';
import { AiOutlineTool } from 'react-icons/ai';
import { BsAward, BsFillArrowRightSquareFill } from 'react-icons/bs';
import { GiThermometerCold } from 'react-icons/gi';
import { GlobalDataContext } from '../../context/context';

function Block_8() {
    const { rpdata } = useContext(GlobalDataContext);

  return (
    <div className='w-full flex justify-center h-auto bgContent-3 '>
        <div className='w-4/5 h-auto bg1 rounded-xl md:flex md:flex-row flex-col justify-between py-2 md:-mt-[120px] -mt-12 z-20'>
            <div className='md:w-1/4 w-full justify-center border-r border-[#ffffff48] items-center flex flex-col py-10 px-5 text-white text-center'>
                <GiThermometerCold color='white' fontSize={45}/>
                <h4>{rpdata?.dbSlogan?.[11]?.slogan}</h4>
            </div>
            <div className='md:w-1/4 w-full hidden justify-center border-r border-[#ffffff48] items-center md:flex flex-col py-10 px-5 text-white text-center'>
                <AiOutlineTool color='white' fontSize={45}/>
                <h4>{rpdata?.dbSlogan?.[12]?.slogan}</h4>
            </div>
            <div className='md:w-1/4 w-full justify-center border-r border-[#ffffff48] items-center flex flex-col py-10 px-5 text-white text-center'>
                <BsAward color='white' fontSize={45}/>
                <h4>{rpdata?.dbSlogan?.[4]?.slogan}</h4>
                <a className='animate-bounce mt-3' href='https://beta.apptracker.ftlfinance.com/C19064'>
                    <BsFillArrowRightSquareFill color='white' fontSize={30}/>
                </a>
            </div>

            <div className='md:w-1/4 w-full hidden justify-center border-r border-[#ffffff48] items-center md:flex flex-col py-10 px-5 text-white text-center'>
                <GiThermometerCold color='white' fontSize={45}/>
                <h4>{rpdata?.dbSlogan?.[13]?.slogan}</h4>
            </div>
        </div>
    </div>
  )
}

export default Block_8