import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { VscDebugBreakpointData } from 'react-icons/vsc'
import { BiPhoneCall } from 'react-icons/bi'


const Block_10 = ({ title, text, text2, listsAbout, listsServices, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <section className="w-full flex justify-center md:py-32 py-10 bgContent-3 ">
                <div className="w-4/5 text-center">
                    {
                        sloganPrincipal ?
                            rpdata?.dbPrincipal?.licensed ?
                                <h2 className='pb-3 capitalize'>
                                    {rpdata?.dbPrincipal?.licensed}
                                </h2>
                                : <h2 className='pb-10 capitalize'>
                                    {
                                        rpdata?.dbPrincipal?.exprYears ?
                                            `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                            : 'we have many years of experience'
                                    }
                                </h2>
                            :
                            <h2 className="separator-about capitalize">
                                {
                                    title ? title :
                                        <span className='lowercase text-[18px]'>
                                            {`default title={'frase'}`} o
                                            {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                        </span>
                                }
                            </h2>
                    }
                    <div className="w-full h-auto px-2 md:px-10 py-10">
                        <p className="pb-4">
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        <div className="w-full flex md:justify-center justify-start items-start md:items-center">
                            {
                                listsAbout ?
                                    <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                        {
                                            rpdata?.dbAbout?.[0].list.length > 1 ?
                                                rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                    return (

                                                        <li key={index} className="py-2 flex items-center">
                                                            <VscDebugBreakpointData />
                                                            <span className="pl-2">{item}</span>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </ul>
                                    : null
                            }
                            {
                                listsServices ?
                                    <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                        {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                            return (
                                                <li key={index} className="py-1 flex items-center">
                                                    <VscDebugBreakpointData />
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : null
                            }
                        </div>
                        <p className="pb-4">
                            {
                                text2 ? text2 :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                    </div>
                    <div className="w-full h-auto md:flex block justify-center items-center">
                        
                        <div className='md:w-[20%] w-full'>
                            <ButtonContent />
                        </div>
                        <div className='flex'>
                            <div className='w-[50px] h-[50px] flex justify-center items-center rounded-full self-center mx-3 bg2'>
                                <BiPhoneCall color='white' fontSize={35} />
                            </div>
                            <div className="">
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                    
                                >
                                    <p className='-mb-3 text-[22px] font-semibold text-start'>Call Us Now!!</p>
                                    <h5 className='text-[28px] font-bold'>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </h5>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default Block_10;