import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../../context/context";
import { ButtonContent } from '../boton/ButtonContent';
import { BiPhoneCall } from 'react-icons/bi'

function Footer4() {
    const { rpdata } = useContext(GlobalDataContext);

    const menu = [
        {
            name: "Home",
            link: "/",
        },
        {
            name: "About",
            link: "/about",
        },
        {
            name: "Gallery",
            link: "/gallery",
        },
        {
            name: "Contact",
            link: "/contact",
        },
        {
            name: "Blog",
            link: "/blog",
        },
    ];

    // agregar la pestaña de reviews al array de dbMenu

    const el = {
        name: `Reviews`,
        link: `/reviews`,
        child: false,
    };
    rpdata?.simpleWidgets?.forEach((element) => {
        const num = menu.length - 1;
        if (element.val === "ReviewTab" && element.activo === true) {
            menu.splice(num, 0, el);
        }
    });
    //  fin de agregar pestaña de reviews

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <div className="w-full flex flex-col justify-center bg-gray-400 bg-opacity-5 p-8 py-14 bg-footer text-white">
                <div className="md:w-3/4 mx-auto text-center flex justify-center mb-4 md:flex-row flex-col-reverse">
                    <div className="md:w-[46%] grid place-content-center py-6  ">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            className="md:w-[400px] w-[280px] object-cover mx-auto pb-10 md:-mt-8 -mt-0"
                            alt="Frank Fence & Construction"
                            loading="lazy"
                        />
                        {
                            rpdata?.simpleWidgets?.[3]?.activo ?
                                null
                                :
                                <div className="w-full h-auto bg-[#000000] z-30">
                                    <ul className="flex flex-col gap-1 md:flex-row pb-3 ">
                                        {menu.map((item, index) => {
                                            return (
                                                <li className="mx-3" onClick={goToTop} key={index}>
                                                    <Link to={item.link} className="flex">
                                                        <span className="pl-2 text-[18px]">{item.name}</span>
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                        }
                    </div>
                </div>
                <div className="w-[90%] mx-auto justify-center md:flex block h-full border-white border rounded-xl p-8 md:-mt-[66px] -mt-0">

                    <div className="lg:w-[35%] w-full block px-3 lg:px-6 py-3 text-start place-content-center">
                        <span className="text-[25px] font-bold">About</span>
                        <p className="pb-4">
                            {
                                rpdata?.simpleWidgets?.[3]?.activo ?
                                    rpdata?.dbAbout?.[1].text
                                    :
                                    rpdata?.dbAbout?.[0].text.substring(0, 280)
                            }
                        </p>
                    </div>
                    <div className="lg:w-[25%] w-full block px-5 py-3 text-start place-content-center">
                        <span className="text-[25px] font-bold">Services</span>
                        <ul className="pl-1">
                            {rpdata?.dbServices?.slice(0, 4).map((item, index) => {
                                return (
                                    rpdata?.simpleWidgets?.[3]?.activo ?
                                        <li className="py-2" key={index}>
                                            <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} className="flex">
                                                <span className="pl-2">{item.name}</span>
                                            </a>
                                        </li>
                                        :
                                        <li className="py-2" onClick={goToTop} key={index}>
                                            <Link to={item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()} className="flex">
                                                <span className="pl-2">{item.name}</span>
                                            </Link>
                                        </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="lg:w-[40%] w-full block px-3 lg:px-12 py-6 text-start space-y-5">
                        <div className="md:pl-4 pl-0">
                            <ButtonContent />
                        </div>
                        <div className='flex  text-white'>
                            <div className='w-[50px] h-[50px] flex justify-center items-center rounded-full self-center mx-3 bg1'>
                                <BiPhoneCall color='white' fontSize={35} />
                            </div>
                            <div>
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                >
                                    <p className='-mb-3 text-[22px] font-semibold'>Call Us Now!!</p>
                                    <h5 className='text-[32px] font-bold'>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className="px-10">
                            <p className="text-[20px]">207 Kent St Merkel, TX 79536, USA.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full h-full flex justify-center items-center bg-footer pb-4">
                <div className="w-full h-auto text-center flex justify-center items-center space-x-3 ">
                    <ul className="text-white flex space-x-2">
                        <li> TACLA102474C</li>
                        <li> - RMP 15631</li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-around md:flex-row items-center flex-col-reverse text-center text-white  py-4 bg2">
                <p className="text-[20px]">
                    ©2023, Copy Right By {rpdata?.dbPrincipal?.name}. All Rights Reserved
                </p>
                <ul className="flex">
                    {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                        return (
                            <li key={index} className="mr-7">
                                <a
                                    href={item.url}
                                    alt={item.name}
                                    aria-label={item.name}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className={`fab fa-${item.icon}`} aria-hidden="true" />
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

export default Footer4;
