import React, { useContext, useState } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { NavLink } from 'react-router-dom';
import { GoChevronDown, GoChevronUp } from 'react-icons/go'
import { BiPhoneCall } from 'react-icons/bi'
import { ButtonContent } from '../boton/ButtonContent';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';




const HeaderOne = () => {
    const { rpdata } = useContext(GlobalDataContext);
    const [openHMenu, setHMenu] = useState(false)

    const [openSubMenu, setOpenSubMenu] = useState(false)
    const [openSubMenuGallery, setOpenSubMenuGallery] = useState(false)

    const handleClickSubMenu = () => {
        return setOpenSubMenu(!openSubMenu)
    }
    const handleClickSubMenuGallery = () => {
        return setOpenSubMenuGallery(!openSubMenuGallery)
    }

    const handleClick = () => {
        return setHMenu(!openHMenu)
    }

    //sub menu
    const subitems = rpdata?.dbServices?.map((itemsMenu, index) => {
        return (
            {
                subname: itemsMenu.name,
                sublink: `/${itemsMenu.name.replaceAll(" ", "-").toLowerCase()}`,
            }
        )
    })

    //sub menu
    const subitemsGallery = rpdata?.landings?.map((itemsGallery, index) => {
        return (
            {
                subname: itemsGallery.name,
                sublink: `/gallery/${itemsGallery.name.replaceAll(" ", "-").toLowerCase()}`,
            }
        )
    })

    const dbMenu = [
        {
            name: `Home`,
            link: `/`,
            child: false,

        },
        {
            name: `About`,
            link: `/about`,
            child: false,
        },
        {
            name: `Services`,
            link: `/services`,
            child: rpdata?.autoGntLandingFromService,
            submenu: [...(subitems ? subitems : [])],
            click: handleClickSubMenu,
            opens: openSubMenu,
        },
        {
            name: `Gallery`,
            link: `/gallery`,
            child: rpdata?.customLinks,
            submenu: [...(subitemsGallery ? subitemsGallery : [])],
            click: handleClickSubMenuGallery,
            opens: openSubMenuGallery,
        },
        {
            name: `Contact`,
            link: `/contact`,
            child: false,
        },
        {
            name: `Blog`,
            link: `/blog`,
            child: false,
        },
    ]



    return (
        <div>
            <div className="bg-white flex justify-center items-center">
                <div className='md:w-1/3 w-[20%] h-auto px-4'>
                    <div className='bg1 rounded-lg md:w-[12%] w-full flex justify-center py-2' onClick={handleClick}>
                        {
                            openHMenu ?
                                <MdClose fontSize={30} color={'white'} />
                                :
                                <GiHamburgerMenu fontSize={30} color={'white'} />
                        }
                    </div>
                </div>
                <div className='md:w-1/3 w-[80%] h-[120px] flex justify-center items-center'>
                    <div className='md:w-1/2 w-[80%] h-full flex justify-center items-center'>
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt='logo'
                            loading='lazy'
                            className='w-[70%] mx-auto'
                        />
                    </div>
                </div>
                <div className='w-1/3 h-auto hidden justify-center items-center md:flex'>
                    <div className='w-[40%]'>
                        <a
                            className='flex'
                            href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                        >
                            <BiPhoneCall color='black' fontSize={35} />
                            <h5 className='text-[21px]'>
                                {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                            </h5>
                        </a>
                    </div>
                    <div className='w-[50%]'>
                        <ButtonContent btnName={"REQUEST SERVICE"} btnLink={'contact'} />
                    </div>
                </div>
            </div>
            {
                openHMenu ?
                    <div className='fixed top-0 z-50 w-full h-full md:h-screen bg-[#00000086] ease-in-out duration-300'>
                        <div className='bg1 w-full md:w-[25%] h-full overflow-y-auto menuScroll'>
                            <div className='flex justify-between w-full py-4 mx-auto items-center bg-white rounded-b-xl'>
                                <div className=''>
                                    <img
                                        src={rpdata?.dbPrincipal?.logo}
                                        alt='logo'
                                        loading='lazy'
                                        className='w-[70%]'
                                    />
                                </div>
                                <div className='bg-red-700 rounded-lg mx-3' >
                                    <MdClose color='white' fontSize={40} onClick={handleClick} />
                                </div>
                            </div>
                            <div className='py-10'>
                                <ul className=' w-4/5 mx-auto'>
                                    {
                                        dbMenu.map((items, index) => {
                                            return (
                                                items.child ?
                                                    <li key={index}
                                                        className='menuContent__subItems text-white font-bold text-[18px]'
                                                        onClick={items.click}
                                                    >
                                                        <div className='flex justify-between items-center'>
                                                            {items.name}
                                                            {
                                                                items.opens ?
                                                                    <GoChevronUp className='ml-1' />
                                                                    : <GoChevronDown className='ml-1' />
                                                            }
                                                        </div>
                                                        <ul className={items.opens ? 'block mt-3 menuContent__subMenuMobil' : 'hidden'}>
                                                            {
                                                                items.submenu.map((itemSub, index) => {
                                                                    return (
                                                                        <NavLink key={index} to={itemSub.sublink}>
                                                                            <li className='p-2'>
                                                                                {itemSub.subname}
                                                                            </li>
                                                                        </NavLink>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                                    :
                                                    <NavLink to={items.link} >
                                                        <li className='menuContent__subItems text-white font-bold text-[18px]'>
                                                            {items.name}
                                                        </li>
                                                    </NavLink>
                                            )
                                        })
                                    }
                                </ul>

                            </div>
                            <div className=' w-[90%] mx-auto flex '>
                                <div className='w-[50px] h-[50px] flex justify-center items-center rounded-full self-center mx-3 bg2'>
                                    <BiPhoneCall color='white' fontSize={35} />
                                </div>
                                <div className=" text-white">
                                    <a
                                        href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}

                                    >
                                        <p className='-mb-3 text-[22px] font-semibold text-start'>Call Us Now!!</p>
                                        <h5 className='text-[28px] font-bold'>
                                            {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                        </h5>
                                    </a>
                                </div>
                            </div>
                            <div className='px-10 mt-4'>
                                <a className='button-book' target={"_blank"}  href='https://book.housecallpro.com/book/Cocina/ab8753edeff44addab011795c8e17014' rel="noreferrer">Bookonline </a>
                            </div>
                            
                            <div className='w-4/5 mx-auto mt-10'>
                                        <ul className="space-x-4 flex pb-2">
                                            {
                                                rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                                    return (
                                                        <li key={index} className=" text-white transition-all ease-in-out duration-300 ">
                                                            <a 
                                                            href={item.url} 
                                                            target="_blank" 
                                                            rel='noopener noreferrer'>
                                                                <i
                                                                    className={`fab fa-${item.icon} text-[20px]`}
                                                                    aria-hidden="true"
                                                                />
                                                            </a>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                            </div>

                        </div>

                    </div>
                    :
                    null
            }
        </div>
    )
}

export default HeaderOne