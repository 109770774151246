import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import CardBlog from "../components/Blog/CardBlog";


function Services() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Blog">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Blog"
          Subheader="Blog"
          bgimg={`${rpdata?.gallery?.[10]}`}
        />
        <CardBlog/>
      </div>
    </BaseLayout>
  );
}

export default Services;
