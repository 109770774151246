import React, { useContext } from 'react'
import { FiMapPin } from 'react-icons/fi'
import { HiPhone } from "react-icons/hi";
import { IoMdMail } from "react-icons/io"
import { GlobalDataContext } from '../../context/context'
import Form from './Form'
import imgDefault from '../../assets/image/placeholder.png'



const ContactInfo5 = ({image}) => {

    const { rpdata } = useContext(GlobalDataContext)
    return (
        <>

            <div className="w-full flex justify-center">
                <div className="flex w-[90%] lg:py-24 py-14 md:flex-row flex-col justify-center text-center md:text-start">

                    <div className=" shadow-2xl md:w-[100%] md:flex flex-row-reverse block w-full md:px-14 lg:bg-white rounded-2xl bg-transparent lg:px-3 px-0">
                        <div className='md:w-[50%] w-full h-auto flex flex-col  bg-white justify-center text-center'>

                            <ul className="flex flex-col h-auto text-center">
                                <div className='w-full bg2 py-10 flex flex-col justify-center md:pl-10 pl-5'>
                                    {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <a
                                                    href={`tel:+1${item.phone}`}
                                                    className="py-2 flex items-center"
                                                >
                                                    <HiPhone className="bg-white p-2 text-[60px] text-color1 rounded-full" />
                                                    <span className="pl-8 font-bold text-[24px] text-white">{item.phone}</span>
                                                </a>
                                            </li>
                                        );
                                    })}

                                    {rpdata?.dbPrincipal?.emails?.[0].email.length > 0 ? (
                                        <li>
                                            <a
                                                href={`mailto:${rpdata?.dbPrincipal?.emails[0].email}`}
                                                className="py-2 flex items-center"
                                            >
                                                <IoMdMail className="bg-white p-2 text-[60px] text-color1 rounded-full" />
                                                <span className="pl-8 font-bold text-[15px] md:text-[24px] text-white">
                                                    {rpdata?.dbPrincipal?.emails[0].email}
                                                </span>
                                            </a>
                                        </li>
                                    ) : null}
                                </div>

                                <div className='w-full bg-2 pl-5 md:pl-10 flex py-10'>
                                    <div className='w-[50px] h-[50px] bg-white flex justify-center items-center rounded-full'>
                                    <FiMapPin fontSize={35}/>
                                    </div>
                                    <div className='w-full md:w-[70%] text-start ml-4 text-white'>
                                        <h4>Location</h4>
                                        <p className="text-[20px]">{rpdata?.dbPrincipal?.location?.[0].address}</p>
                                        </div>
                                </div>

                                <div className='w-full h-[300px]'>
                                    <img
                                        src={image ? image : imgDefault}
                                        alt='no found'
                                        loading='lazy'
                                        className='w-full h-full object-cover'
                                    />

                                </div>

                            </ul>
                            
                        </div>
                        <div className='md:w-[50%] w-full h-auto lg:mt-0 mt-5 bg-white lg:p-0 p-5 text-center self-center'>
                            <h2>Send Us A Message</h2>
                            <Form />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ContactInfo5