import React, {useContext} from 'react'
import { GlobalDataContext } from "../../context/context";




const CounterUp = ({image}) => {

    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bgCountent' style={{backgroundImage: `url("${image ? image : rpdata?.stock?.[1]}")` }}>
            <h2 className='text-center py-4 relative text-white'>{rpdata?.dbSlogan?.[4].slogan}</h2>
            
        </div>
    )
}
export default CounterUp
